/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from '../../assests/images/icons/logo.svg';
import { ReactComponent as InstgramIcon } from '../../assests/images/icons/footer_instgram.svg';
import { ReactComponent as LinkedinIcon } from '../../assests/images/icons/footer_linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assests/images/icons/footer_twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../assests/images/icons/footer_youtube.svg';
import { ReactComponent as FaceBookIcon } from '../../assests/images/icons/footer_facebook.svg';
import './index.scss';
export default function Index() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className='container'>
        <div className='upper-footer'>
          <div className='links'>
            <ul>
              <li>
                <Link to='/'>
                  <LogoIcon />
                </Link>
              </li>
              <li>
                <Link to='/'>{t('home')}</Link>
              </li>
              <li>
                <Link to='/about'>{t('about')}</Link>
              </li>
              <li>
                <Link to='/careers'>{t('careers')}</Link>
              </li>
              <li>
                <Link to='/contact-us'>{t('contact_us')}</Link>
              </li>
            </ul>
          </div>
          <div className='social-icons'>
            <ul>
              <li>
                <a href='#'>
                  <FaceBookIcon />
                </a>
              </li>
              <li>
                <a href='#'>
                  <InstgramIcon />
                </a>
              </li>
              <li>
                <a href='#'>
                  <LinkedinIcon />
                </a>
              </li>
              <li>
                <a href='#'>
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a href='#'>
                  <YoutubeIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='divider'></div>
        <div className='copy-rights'>
          <div>
            Copyright © 2022 <span>T-Transit</span> - All rights reserved
          </div>
          <ul>
            <li>
              <Link to='#'>Privacy policy</Link>
            </li>
            <li>
              <Link to='#'>Terms & conditions</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
