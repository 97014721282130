import React from 'react';

import { headerData } from '../data/data';
import Navbar from '../../../components/navbar';
import AboutImage from '../../../assests/images/about.png';
import './index.scss';
import { useTranslation } from 'react-i18next';
const Index = () => {
  const { i18n } = useTranslation();
  return (
    <section className='about-header'>
      <Navbar />
      <div className='about-header-content'>
        <div className='container'>
          <h3>
            {i18n.language === 'en' ? headerData.title_en : headerData.title_ar}
          </h3>
          <p>
            {i18n.language === 'en' ? headerData.desc_en : headerData.desc_ar}
          </p>
          <img src={AboutImage} alt='AboutImage' />
        </div>
      </div>
    </section>
  );
};

export default Index;
