import { useQuery, useMutation } from 'react-query';
import { request } from '../utils/axios-utils';

const fetchData = (url) => {
  return request({ url: `/${url}` });
};

const makeOrder = (oreder) => {
  return request({ url: `/orders`, method: 'post', data: oreder });
};

const contactus = (data) => {
  return request({ url: `/contact-request`, method: 'post', data: data });
};
export const useData = (
  onSuccess = () => {},
  onError = () => {},
  key,
  url,
  staleTime = 0
) => {
  return useQuery(`${key}`, () => fetchData(url), {
    staleTime: staleTime,
    onSuccess,
    onError,
  });
};
export const useMakeOrder = (onSuccess, onError) => {
  return useMutation(makeOrder, { onSuccess, onError });
};
export const useContactUs = (onSuccess, onError) => {
  return useMutation(contactus, { onSuccess, onError });
};
