import React from 'react';

import Navbar from '../../components/navbar';
import Details from './details';
import Form from './form';
import OurFeatures from '../../components/ourFeatures';
import { featuresData } from './data';
import './index.scss';
export default function index() {
  return (
    <div className='contact-us'>
      <Navbar />
      <div className='contact-us-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <Details />
            </div>
            <div className='col-md-6'>
              <Form />
            </div>
          </div>
        </div>
        <div className='background'></div>
      </div>
      <OurFeatures data={featuresData} />
    </div>
  );
}
