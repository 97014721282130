import React from 'react';
import { useTranslation } from 'react-i18next';

import { contactDetails } from '../data';

import './index.scss';
const Index = () => {
  const { i18n } = useTranslation();
  return (
    <div className='details'>
      <h3>
        {i18n.language === 'en'
          ? contactDetails.title_en
          : contactDetails.title_ar}
      </h3>
      <h4>
        {i18n.language === 'en'
          ? contactDetails.subtitle_en
          : contactDetails.subtitle_ar}
      </h4>
      <p>
        {i18n.language === 'en'
          ? contactDetails.desc_en
          : contactDetails.desc_ar}
      </p>
      {contactDetails?.contactData.map((item) => {
        return (
          <div className='contact-item' key={item.id}>
            <div className='contect-item-icon'>
              <img src={item.icon} alt='contctIcon' />
            </div>
            <div className='contact-item-data'>
              <h3>{i18n.language === 'en' ? item.title_en : item.title_ar}</h3>
              <p>{item.contact_way}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
