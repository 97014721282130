import React from 'react';
import { Tabs } from 'antd';

import About from './about';
import Services from '../../../components/services';
import { servicesData } from '../data/data';
import './index.scss';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const Index = () => {
  const { t } = useTranslation();
  return (
    <section className='descrip-us'>
      <Tabs defaultActiveKey='1' centered>
        <TabPane tab={t('about')} key='1'>
          <About />
        </TabPane>
        <TabPane tab={t('businesses')} key='2'>
          <Services data={servicesData} showList={false} />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default Index;
