import React from 'react';
import ServiceInfo from './serviceInfo';
import ServiceForm from './ServiceForm';
import './index.scss';

const Index = () => {
  return (
    <div className='service-order'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <ServiceInfo />
          </div>
          <div className='col-md-6'>
            <ServiceForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
