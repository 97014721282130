import React from 'react';
import { Form, Input } from 'antd';
import { toast } from 'react-toastify';

import { ReactComponent as ArabicFlagIcon } from '../../../assests/images/icons/ar.svg';
import { ReactComponent as DownIcon } from '../../../assests/images/icons/down.svg';
import TButton from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useContactUs } from '../../../hooks/useData';
const Index = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onContactFail = (error) => {
    console.log('error', error);
  };
  const onContactSuccess = () => {
    form.resetFields();

    toast.success(t('contact_us_message'), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      pauseOnHover: false,
    });
  };
  const { mutate: contactUs } = useContactUs(onContactSuccess, onContactFail);

  const onFinish = (values) => {
    contactUs(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const prefixSelector = (
    <span className='phone-flag'>
      <ArabicFlagIcon /> +2
      <span>
        <DownIcon />
      </span>
    </span>
  );
  return (
    <div className='form'>
      {' '}
      <h3>{t('get_touch')}</h3>
      <Form
        name='orderService'
        onFinish={onFinish}
        form={form}
        layout='vertical'
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        initialValues={{
          'service-type': 'Logistics',
        }}
      >
        <Form.Item name='name' label={t('name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='mobile'
          label={t('mobile')}
          className='phone'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            prefix={prefixSelector}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item
          name='email'
          label={t('email')}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='message'
          label={t('message')}
          rules={[{ required: true }]}
        >
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>

        <TButton text_key='speak_to_an_expert' width='large' />
      </Form>
    </div>
  );
};

export default Index;
