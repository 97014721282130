import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../assests/images/icons/check.svg';
import './index.scss';
const Index = ({ listItem }) => {
  const { i18n } = useTranslation();
  return (
    <div className='list'>
      {listItem?.map((item, index) => {
        return (
          <div className='list-item' key={item.id}>
            <div className='item-icon'>
              <CheckIcon />
            </div>
            <div className='item-text'>
              {i18n.language === 'en' ? item.desc_en : item.desc_ar}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
