import React from 'react';

import Header from './header';
import Statictics from '../../components/statictics';
import OrderService from '../../components/orderService';
import Services from '../../components/services';
import OurFeatures from '../../components/ourFeatures';
import OurCustomers from './ourCustomers';
import { servicesData, featuresData } from './data/data.js';

const Index = () => {
  return (
    <>
      <Header />
      <Statictics />
      <Services data={servicesData} />
      <OurFeatures data={featuresData} />
      {/* <OurCustomers /> */}
      <OrderService />
    </>
  );
};
export default Index;
