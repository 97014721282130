import React from 'react';
import WhoWEARE from './whoWeAre';
import Statictics from '../../../../components/statictics';
// import TeamImage from '../../../../assests/images/team.png';
import './index.scss';
const Index = () => {
  return (
    <>
      <WhoWEARE />
      <Statictics />
      {/* <div className='team'>
        <img src={TeamImage} alt='TeamImage' />
      </div> */}
    </>
  );
};
export default Index;
