export const data = {
  first: {
    number: 89,
    title_en: 'Success Rate',
    title_ar: 'نسبة نجاح التوصيل',
    subTitle_en: 'in fulfilling orders',
    subTitle_ar: 'in fulfilling orders',
  },
  second: {
    number: 93,
    title_en: 'Ecommerce businesses',
    title_ar: 'شركات شحن',
    subTitle_en: 'across our headquarters',
    subTitle_ar: 'across our headquarters',
  },
  third: {
    number: 25,
    title_en: 'Governorate',
    title_ar: 'محافظة',
    subTitle_en: 'in fulfilling orders',
    subTitle_ar: 'in fulfilling orders',
  },
  fourth: {
    number: 98,
    title_en: 'SLA Compliance',
    title_ar: 'تسليم في الميعاد',
    subTitle_en: 'in fulfilling orders',
    subTitle_ar: 'in fulfilling orders',
  },
};
