import React from 'react';
import { useTranslation } from 'react-i18next';
import { whoWeAreData } from '../../../data/data';
import './index.scss';

const Index = () => {
  const { i18n } = useTranslation();
  return (
    <section className='whow-we-are'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='whow-we'>
              <h3>
                {i18n.language === 'en'
                  ? whoWeAreData.title_en
                  : whoWeAreData.title_ar}
              </h3>
              {whoWeAreData?.missionAndvision?.map((item) => {
                return (
                  <div className='mission-vision' key={item.id}>
                    <h4>
                      {i18n.language === 'en' ? item.title_en : item.title_ar}
                    </h4>
                    <p>
                      {i18n.language === 'en'
                        ? item.description_en
                        : item.description_ar}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='description'>
              {i18n.language === 'en'
                ? whoWeAreData.description_en
                : whoWeAreData.description_ar}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
