import React from 'react';
import Header from './header';
import DescripUs from './descripus';
import OrderService from '../../components/orderService';
export default function index() {
  return (
    <div className='about'>
      <Header />
      <DescripUs />
      <OrderService />
    </div>
  );
}
