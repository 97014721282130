import React from 'react';
import { useTranslation } from 'react-i18next';
import T2D from '../../assests/images/t_2d.png';
import './index.scss';

const Index = ({ data }) => {
  const { i18n } = useTranslation();
  return (
    <section className='our-features'>
      <div className='container'>
        <h3>{i18n.language === 'en' ? data.title_en : data.title_ar}</h3>
        <p>{i18n.language === 'en' ? data.praghrap_en : data.praghrap_ar}</p>
        <div className='features'>
          <div className='row'>
            {data?.featuesDetails?.map((feature) => {
              return (
                <div className='col-md-4' key={feature.id}>
                  <div className='feature'>
                    <img src={feature.image_url} alt='feature' />
                    <h5>
                      {i18n.language === 'en'
                        ? feature.title_en
                        : feature.title_ar}
                    </h5>
                    <p>
                      {i18n.language === 'en'
                        ? feature.praghrap_en
                        : feature.praghrap_ar}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='t-2d'>
        <img src={T2D} alt='t2d' />
      </div>
    </section>
  );
};

export default Index;
