import React, { useState } from 'react';
import { Form, Select, Input } from 'antd';
import { toast } from 'react-toastify';

import { ReactComponent as ArabicFlagIcon } from '../../assests/images/icons/ar.svg';
import { ReactComponent as DownIcon } from '../../assests/images/icons/down.svg';
import TButton from '../Button';
import { useTranslation } from 'react-i18next';
import { useData, useMakeOrder } from '../../hooks/useData';

const { Option } = Select;

const ServiceForm = ({ handelCancel = () => {} }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [pickUpRegion, setPickUpRegion] = useState([]);
  const [dropOffRegion, setDropOffRegion] = useState([]);
  const [serviveType, setServiveType] = useState(14);
  const { data: cities } = useData(
    () => {},
    () => {},
    'cities-areas',
    'cities-areas',
    50000
  );
  const { data: serviceType } = useData(
    () => {},
    () => {},
    'service-type',
    'orders/service-type',
    50000
  );
  const { data: accountTypes } = useData(
    () => {},
    () => {},
    'account-type',
    'orders/account-type',
    50000
  );
  const { data: categories } = useData(
    () => {},
    () => {},
    'industry',
    'orders/industry',
    50000
  );
  const onMakeOrderFail = (data) => {
    toast.success(data.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      pauseOnHover: false,
    });
  };
  const onMakeOrderSuccess = () => {
    form.resetFields();
    handelCancel();
    toast.success(t('make_orderSuccess'), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      pauseOnHover: false,
    });
  };
  const { mutate: makeOrder, isLoading } = useMakeOrder(
    onMakeOrderSuccess,
    onMakeOrderFail
  );
  const onFinish = (values) => {
    makeOrder(values);
  };

  const handalServiceChange = (value) => {
    setServiveType(value);
  };
  const handlePickupCityChange = (value) => {
    const city = cities?.data?.filter((city) => city.id === value);
    setPickUpRegion([...city[0].areas]);
  };
  const handleDropOffCityChange = (value) => {
    const city = cities?.data?.filter((city) => city.id === value);
    setDropOffRegion([...city[0].areas]);
  };

  const prefixSelector = (
    <span className='phone-flag'>
      <ArabicFlagIcon /> +2
      <span>
        <DownIcon />
      </span>
    </span>
  );
  return (
    <div className='service-form'>
      <Form
        name='orderService'
        onFinish={onFinish}
        form={form}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError={true}
      >
        <Form.Item
          name='serviceType'
          label={t('service_type')}
          rules={[{ required: true, message: t('required') }]}
          initialValue={14}
        >
          <Select
            onChange={handalServiceChange}
            allowClear={false}
            suffixIcon={<DownIcon />}
          >
            {serviceType?.data?.map((service) => {
              return (
                <Option key={service.id} value={service.id}>
                  {i18n.language === 'en' ? service.name : service.nameAR}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name='name' label={t('name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='phoneNumber'
          label={t('mobile')}
          className='phone'
          rules={[{ required: true, message: t('required') }]}
        >
          <Input
            prefix={prefixSelector}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        {serviveType === 14 ? (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <Form.Item
                  name='pickUpCity'
                  label={t('pick_up_city')}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <Select
                    allowClear={false}
                    suffixIcon={<DownIcon />}
                    onChange={(value) => handlePickupCityChange(value)}
                  >
                    {cities?.data?.map((city) => {
                      return (
                        <Option key={city.id} value={city.id}>
                          {i18n.language === 'en' ? city.name : city.nameAr}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-md-6'>
                <Form.Item
                  name='pickUpArea'
                  label={t('pick_up_region')}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <Select allowClear={false} suffixIcon={<DownIcon />}>
                    {pickUpRegion?.map((region) => {
                      return (
                        <Option key={region.id} value={region.id}>
                          {' '}
                          {i18n.language === 'en' ? region.name : region.nameAR}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-md-6'>
                <Form.Item
                  name='dropOffCity'
                  label={t('drop_off_city')}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <Select
                    allowClear={false}
                    suffixIcon={<DownIcon />}
                    onChange={(value) => handleDropOffCityChange(value)}
                  >
                    {cities?.data?.map((city) => {
                      return (
                        <Option key={city.id} value={city.id}>
                          {i18n.language === 'en' ? city.name : city.nameAr}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-md-6'>
                <Form.Item
                  name='dropOffArea'
                  label={t('drop_off_region')}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <Select allowClear={false} suffixIcon={<DownIcon />}>
                    {dropOffRegion?.map((region) => {
                      return (
                        <Option key={region.id} value={region.id}>
                          {' '}
                          {i18n.language === 'en' ? region.name : region.nameAR}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div> */}

            <Form.Item
              name='accountType'
              label={t('contract_type')}
              rules={[{ required: true, message: t('required') }]}
            >
              <Select allowClear={false} suffixIcon={<DownIcon />}>
                {accountTypes?.data?.map((contract) => {
                  return (
                    <Option key={contract.id} value={contract.id}>
                      {i18n.language === 'en' ? contract.name : contract.nameAR}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </>
        ) : null}
        <Form.Item
          name='industry'
          label={t('category')}
          rules={[{ required: true, message: t('required') }]}
        >
          <Select allowClear={false} suffixIcon={<DownIcon />}>
            {categories?.data?.map((category) => {
              return (
                <Option key={category.id} value={category.id}>
                  {i18n.language === 'en' ? category.name : category.nameAR}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        
        {serviveType !== 14 ? ( 
        <Form.Item name='averageMonthlyShipments' label={t('averageMonthlyShipments')}
         rules={[{ required: true ,
          message:"Average must number",
          pattern: new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)
          }]}>
          <Input />
        </Form.Item>
        ):null }

        <TButton
          text_key='receive_call'
          width='large'
          loading={isLoading}
          callback={form.submit}
        />
      </Form>
    </div>
  );
};

export default ServiceForm;
