import React, { useEffect, useState } from 'react';

import Footer from '../components/footer';
import { ReactComponent as UpwardIcon } from '../assests/images/icons/upward.svg';
import { ToastContainer } from 'react-toastify';
export default function AppLayout(props) {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className='top-to-btm'>
        {showTopBtn && (
          <button className='icon-position icon-style' onClick={goToTop}>
            <UpwardIcon />
          </button>
        )}
      </div>
      {props.children}
      <Footer />
      <ToastContainer autoClose={2000} />
    </>
  );
}
