/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GetStarted from '../getstarted';
import { ReactComponent as CloseMenu } from '../../assests/images/icons/x.svg';
import { ReactComponent as MenuIcon } from '../../assests/images/icons/menu.svg';
import { ReactComponent as LogoIcon } from '../../assests/images/icons/logo.svg';
import ARFlag from '../../assests/images/icons/ar.png';
import ENFlag from '../../assests/images/icons/en.png';
import TButton from '../Button';
import './index.scss';

const Index = () => {
  const { t, i18n } = useTranslation();
  const [visibleModel, setVisibleModel] = useState(false);
  const [state, setState] = useState({
    click: false,
    nav: false,
    flag: null,
  });

  const handleClick = () => {
    setState((s) => ({ ...s, click: !s.click }));
  };
  const closeMobileMenu = () => {
    setState((s) => ({ ...s, click: false }));
  };
  const switchLangauage = () => {
    let lang = localStorage.getItem('lang');
    let html = document.getElementsByTagName('html')[0];
    if (lang === 'ar') {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
      html.classList.remove('arabic_version');
      html.setAttribute('dir', 'ltr');
    } else {
      i18n.changeLanguage('ar');
      localStorage.setItem('lang', 'ar');
      html.classList.add('arabic_version');
      html.setAttribute('dir', 'rtl');
    }
  };
  const handleOpenModel = () => {
    setVisibleModel(true);
    closeMobileMenu();
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50 || window.pageYOffset > 50) {
        document.getElementById('navbar').classList.add('nav-fixed');
      } else {
        document.getElementById('navbar').classList.remove('nav-fixed');
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  return (
    <div className='responsive_nav' id='navbar'>
      <div className='container'>
        <div className='logo-nav'>
          <div className='mobile-header'>
            {' '}
            <LogoIcon />
            <div className='right'>
              <button className='switch_language' onClick={switchLangauage}>
                <span className='icon'>
                  {i18n.language === 'ar' ? (
                    <img src={ARFlag} alt='ar' />
                  ) : (
                    <img src={ENFlag} alt='en' />
                  )}
                </span>
                <span className='text'>{t('language')}</span>
              </button>
              <MenuIcon className='menu-icon' onClick={handleClick} />
            </div>
          </div>
          <ul className={state.click ? 'nav-options active' : 'nav-options'}>
            <div className='mobile-menu' onClick={handleClick}>
              <LogoIcon />
              {state.click ? (
                <CloseMenu className='menu-icon' />
              ) : (
                <MenuIcon className='menu-icon' />
              )}
            </div>
            <div className='right_menu'>
              <li className='logo_link'>
                <NavLink to=''>
                  <LogoIcon />
                </NavLink>
              </li>
              <li className='option' onClick={closeMobileMenu}>
                <NavLink to='/'>{t('home')}</NavLink>
              </li>
              <li className='option' onClick={closeMobileMenu}>
                <NavLink to='/about'>{t('about')}</NavLink>
              </li>
              <li className='option' onClick={closeMobileMenu}>
                <NavLink to='/careers'>{t('careers')}</NavLink>
              </li>
              <li className='option' onClick={closeMobileMenu}>
                <NavLink to='/contact-us'>{t('contact_us')}</NavLink>
              </li>
            </div>
            <div className='left_menu nav_buttons'>
              <a
                href='https://dashboard.t-transit.com/auth/login'
                target='_blank'
              >
                login
              </a>

              <TButton text_key='get_started' callback={handleOpenModel} />
              <button className='switch_language' onClick={switchLangauage}>
                <span className='icon'>
                  {i18n.language === 'ar' ? (
                    <img src={ARFlag} alt='ar' />
                  ) : (
                    <img src={ENFlag} alt='en' />
                  )}
                </span>
                <span className='text'>{t('language')}</span>
              </button>
            </div>
          </ul>
        </div>
      </div>
      <GetStarted
        isOpen={visibleModel}
        isCancel={() => setVisibleModel(false)}
      />
    </div>
  );
};

export default Index;
