/* eslint-disable react/no-typos */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../assests/images/icons/east.svg';

import './index.scss';
import { Button } from 'antd';
const Index = ({
  text_key,
  type = 'fulfilled',
  width = 'small',
  icon = false,
  callback,
  loading = false,
}) => {
  const { t } = useTranslation();
  console.log('loading', loading);
  return (
    <Button
      className={classNames(width, type, {
        'main-btn': true,
      })}
      onClick={callback}
      loading={loading}
      type='default'
    >
      {t(text_key)}{' '}
      {icon && (
        <span className='btn-icon'>
          <ArrowIcon />
        </span>
      )}
    </Button>
  );
};
Index.propTypes = {
  text_key: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.bool,
};

export default Index;
