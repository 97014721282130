import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { data } from './data';
import './index.scss';
const Index = () => {
  const { i18n } = useTranslation();
  return (
    <div className='statictics'>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-6 col-md-3 mb-4'>
            <div className='statcistics-data'>
              <div className='numbers'>
                <CountUp end={data?.first?.number} duration={5} />%
              </div>
              <div className='title'>
                {i18n.language === 'en'
                  ? data?.first?.title_en
                  : data?.first?.title_ar}
              </div>
              <div className='sub-title'>
                {i18n.language === 'en'
                  ? data?.first?.subTitle_en
                  : data?.first?.subTitle_ar}
              </div>
            </div>
          </div>
          <div className='col-6 col-md-3 mb-4'>
            <div className='statcistics-data'>
              <div className='numbers'>
                <CountUp end={data?.second?.number} duration={5} />
              </div>
              <div className='title'>
                {i18n.language === 'en'
                  ? data?.second?.title_en
                  : data?.second?.title_ar}
              </div>
              <div className='sub-title'>
                {i18n.language === 'en'
                  ? data?.second?.subTitle_en
                  : data?.second?.subTitle_ar}
              </div>
            </div>
          </div>
          <div className='col-6 col-md-3'>
            <div className='statcistics-data'>
              <div className='numbers'>
                <CountUp end={data?.third?.number} duration={5} />
              </div>
              <div className='title'>
                {i18n.language === 'en'
                  ? data?.third?.title_en
                  : data?.third?.title_ar}
              </div>
              <div className='sub-title'>
                {i18n.language === 'en'
                  ? data?.third?.subTitle_en
                  : data?.third?.subTitle_ar}
              </div>
            </div>
          </div>
          <div className='col-6 col-md-3'>
            <div className='statcistics-data'>
              <div className='numbers'>
                <CountUp end={data?.fourth?.number} duration={5} />%
              </div>
              <div className='title'>
                {' '}
                {i18n.language === 'en'
                  ? data?.fourth?.title_en
                  : data?.fourth?.title_ar}
              </div>
              <div className='sub-title'>
                {i18n.language === 'en'
                  ? data?.fourth?.subTitle_en
                  : data?.fourth?.subTitle_ar}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
