import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GetStarted from '../../../components/getstarted';
import { headerData } from '../data/data';
import Navbar from '../../../components/navbar';
import List from '../../../components/list';
import TButton from '../../../components/Button';
import HeaderImage from '../../../assests/images/header.png';
import Background from '../../../assests//images/header-background.png';
import './index.scss';

const Index = () => {
  const { i18n } = useTranslation();
  const [visibleModel, setVisibleModel] = useState(false);
  const handleOpenModel = () => {
    setVisibleModel(true);
  };
  return (
    <section className='header'>
      <Navbar />
      <div className='header-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='header-info'>
                <h1>
                  {i18n.language === 'en'
                    ? headerData.title_en
                    : headerData.title_ar}
                </h1>
                <h4>
                  {i18n.language === 'en'
                    ? headerData.subtitle_en
                    : headerData.subtitle_ar}
                </h4>
                <p>
                  {i18n.language === 'en'
                    ? headerData.desc_en
                    : headerData.desc_ar}
                </p>
                <TButton
                  width='medium'
                  text_key='receive_call'
                  callback={handleOpenModel}
                />
                <List listItem={headerData?.list_items} />
              </div>
            </div>
            <div className='col-md-7'>
              <div className='header-media'>
                <img src={HeaderImage} alt='headerImage' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='background'>
        <img src={Background} alt='background' />
      </div>
      <GetStarted
        isOpen={visibleModel}
        isCancel={() => setVisibleModel(false)}
      />
    </section>
  );
};

export default Index;
