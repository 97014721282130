export const orderServiceData = {
  title_en: 'SELECT THE SOLUTION YOU NEED',
  title_ar: 'حدد الخدمة التي تحتاجها',
  subtitle_en: 'Your customer is a click away',
  subtitle_ar: 'عميلك على بعد ضغطة واحدة',
  list_items: [
    { id: 1, desc_en: 'Business partner', desc_ar: 'شركاء عمل' },
    { id: 2, desc_en: 'Higher quality', desc_ar: 'أعلى جودة' },
    { id: 3, desc_en: 'Lower cost', desc_ar: 'أقل تكلفة' },
  ],
};
