import React from 'react';
import Navbar from '../../components/navbar';
import CareersImage from '../../assests/images/about.png';
import './index.scss';
function index() {
  return (
    <div className='careers'>
      <Navbar />
      <div className='container'>
        <h3>COMING SOON</h3>
        <p>Stay tuned, something cool is coming</p>
        <img src={CareersImage} alt='careersImage' />
      </div>
    </div>
  );
}

export default index;
