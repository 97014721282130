import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.scss';
import ServiceForm from '../orderService/ServiceForm';

const Index = ({ isOpen, isCancel }) => {
  const { t } = useTranslation();

  const handelCancel = () => {
    isCancel();
  };
  return (
    <div className='get-started'>
      <Modal
        title={t('get_started')}
        footer={false}
        visible={isOpen}
        onCancel={isCancel}
        className='get-started-model'
      >
        <p>{t('pop_title')}</p>
        <ServiceForm handelCancel={handelCancel} />
      </Modal>
    </div>
  );
};

export default Index;
