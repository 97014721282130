export const headerData = {
  title_en: 'FOCUS ON GROWING YOUR BUSINESS AND LEAVE THE REST UP TO US',
  title_ar: 'ركز على تنمية أعمالك واترك الأمر لنا',
  desc_en:
    'We aim to manage the end to end fulfillment cycle on your behalf, allowing you to focus solely on growing your business. We manage your inventory, pick, pack and ship your orders while giving you real time visibility every step of the way.',
  desc_ar:
    'نهدف إلى إدارة دورة الإنجاز الشاملة نيابة عنك ، مما يسمح لك بالتركيز فقط على تنمية أعمالك. نحن ندير مخزونك ، وننتقي طلباتك ونحزمها ونشحنها بينما نوفر لك رؤية في الوقت الفعلي في كل خطوة على الطريق',
};
export const whoWeAreData = {
  id: 1,
  title_en: 'WHO WE ARE',
  title_ar: 'من نحن',
  missionAndvision: [
    {
      id: 1,
      title_en: 'Mission',
      title_ar: 'مهمتنا',
      description_en:
        'We invest and believe in people and technology, to make our customers, partners and employees lives easier.',
      description_ar:
        'نستثمر ونؤمن بالناس والتكنولوجيا ، لنجعل حياة موظفي عملائنا وشركائنا أسهل. ليس هذا فقط في حال كنت ترغب في زيادة مبيعاتك ، فلدينا خدمات برنامج الانتساب لتعظيم وتوسيع نطاق عملك يتم ملاحظتك وزيادة أرباحك ، ومع ذلك فنحن نقدم لك أفضل الأسعار في السوق بالإضافة إلى المرونة في المدفوعات وتحصيل الأموال والنظرة العامة لتقارير عملك وتتبع الشحنات والتقارير المنظمة.',
    },
    {
      id: 2,
      title_en: 'Vision',
      title_ar: 'رؤيتنا',
      description_en:
        'The platform for various secured services with only a couple of clicks away from an outstanding service with reasonable prices.',
      description_ar: 'منصة واحدة توفر لك أفضل خدمة مقابل سعر لدعم عملك',
    },
  ],
  description_en:
    'At T-Transit, we offer all our customers various services in terms of logistics solutions services. We connect you to a pool of logistics companies to secure your shipments and track your shipments to govern for you the business continuity. Not only this in case you want to boost your sales we have our affiliation program services to maximize and expand your scope of business getting noticed and increase your earnings, yet we’re offering you the best prices in the market in addition to flexibility in payments, cash collection and overview for your business reports, shipments trackability and governed reports.',
  description_ar:
    'نقدم لجميع عملائنا خدمات متنوعة من حيث خدمات الحلول اللوجستية. نقوم بتوصيلك بمجموعة من الشركات اللوجستية لتأمين شحناتك وتتبع شحناتك للتحكم في استمرارية عملك. ليس هذا فقط في حالة رغبتك في زيادة مبيعاتك ، فلدينا خدمات برنامج  لزيادة وتوسيع نطاق عملك التجاري وزيادة أرباحك ، ومع ذلك فنحن نقدم لك أفضل الأسعار في السوق بالإضافة إلى المرونة في المدفوعات والتحصيل النقدي ونظرة عامة على تقارير أعمالك وتتبع الشحنات والتقارير المحكومة.',
};

export const servicesData = [
  {
    id: 1,
    title_en: 'LOGISTICS SOLUTION',
    title_ar: 'خدمة لوجستية متكاملة',
    praghrap_en:
      'Now you can ship, store, return your shipments with the best logistics service providers at the lowest cost in the market. Also, we are going to fully manage your shipments by making follow-ups on the shipping company, validating the rejection reasons with the customers, and giving you instant updates on your shipments.',
    praghrap_ar:
      'يمكنك الآن شحن وتخزين وإرجاع شحناتك مع أفضل مزودي الخدمات اللوجستية بأقل تكلفة في السوق. أيضًا ، سندير شحناتك بشكل كامل من خلال متابعة شركة الشحن ، والتحقق من أسباب الرفض مع العملاء ، وإعطائك تحديثات فورية عن شحناتك.',
    image_url: require('../../../assests/images/affiliation-service.png'),
  },
  {
    id: 2,
    title_en: 'AFFILIATION SERVICE',
    title_ar: 'تسويق منتجاتك',
    praghrap_en:
      'All merchants want to increase the orders they receive, now you’re going to share with us only your product details, and we are going to market for your products so you can see massive increase in your daily orders.',
    praghrap_ar:
      'يرغب جميع التجار في زيادة الطلبات التي يتلقونها ، والآن ستشارك معنا فقط تفاصيل منتجك ، وسنقوم بتسويق منتجاتك حتى تتمكن من رؤية زيادة هائلة في طلباتك اليومية',
    image_url: require('../../../assests/images/logistics-service.png'),
  },
];
