import React from 'react';

import { orderServiceData } from './data';
import List from '../../components/list';
import { useTranslation } from 'react-i18next';
const ServiceInfo = () => {
  const { i18n } = useTranslation();

  return (
    <div className='service-info'>
      <h3>
        {i18n.language === 'en'
          ? orderServiceData.title_en
          : orderServiceData.title_ar}
      </h3>
      <h4>
        {i18n.language === 'en'
          ? orderServiceData.subtitle_en
          : orderServiceData.subtitle_ar}
      </h4>
      <List listItem={orderServiceData?.list_items} />
    </div>
  );
};
export default ServiceInfo;
