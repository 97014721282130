export const contactDetails = {
  title_en: 'CONTACT US',
  title_ar: 'تواصل معنا',
  subtitle_en: 'Have any questions?',
  subtitle_ar: 'لديك أي استفسار',
  desc_en:
    'We’re here to answer your questions and help you find the right solution for your business.',
  desc_ar:
    'نحن هنا للإجابة على أسئلتك ومساعدتك في العثور على الحل المناسب لعملك',
  contactData: [
    {
      id: 1,
      title_en: 'Contact us',
      title_ar: 'تواصل معنا',
      contact_way: '01098336623',
      icon: require('../../assests/images/contact.png'),
    },
    {
      id: 2,
      title_en: 'Message us',
      title_ar: 'أرسل لنا',
      contact_way: 'auto.reply@t-transit.com',
      icon: require('../../assests/images/message.png'),
    },
    {
      id: 3,
      title_en: 'Visit our office',
      title_ar: 'قم بزيارتنا',
      contact_way: '190 – New Cairo1 – Cairo Gov',
      icon: require('../../assests/images/location.png'),
    },
  ],
};
export const featuresData = {
  title_en: 'A PARTNER THAT YOU TRUST',
  title_ar: 'شريك تثق به',
  featuesDetails: [
    {
      id: 1,
      image_url: require('../../assests/images/icons/insurance.png'),
      title_en: 'Free Insurance',
      title_ar: 'تأمين مجاني',
      praghrap_en:
        'We provide a variety of insurance options in the case of damaged or lost orders',
      praghrap_ar: 'نحن نقدم التأمين في حالة التلف أو الخسارة',
    },
    {
      id: 2,
      image_url: require('../../assests/images/icons/pickup.png'),
      title_en: 'Free Pickup',
      title_ar: 'تحصيل مجاني',
      praghrap_en:
        'Get your orders picked up for free by your selected courier company.',
      praghrap_ar: 'اختر شركة الشحن لتحصل طلباتك مجانا',
    },
    {
      id: 3,
      image_url: require('../../assests/images/icons/analysis.png'),
      title_en: 'Analysis',
      title_ar: 'تحليل بيانات',
      praghrap_en:
        'Our dashboard helps you extract data to analyze and compare your profits.',
      praghrap_ar:
        'تساعدك الداشبورد الخاصة بنا على استخراج البيانات لتحليل الإحصائيات ومقارنتها',
    },
    {
      id: 4,
      image_url: require('../../assests/images/icons/tracking.png'),
      title_en: 'Tracking',
      title_ar: 'تتبع',
      praghrap_en:
        'Just like the magical ball, you can track them through our dashboard anywhere, anytime',
      praghrap_ar:
        'يمكنك تتبع طلباتك من خلال الداشبورد الخاصة بنا في أي وقت وفي أي مكان',
    },
    {
      id: 5,
      image_url: require('../../assests/images/icons/upload.png'),
      title_en: 'Bulk Upload Orders',
      title_ar: 'سجل طلباتك في خطوة واحدة',
      praghrap_en:
        'You can easily upload all your orders in a bulk using an excel sheet.',
      praghrap_ar: 'يمكنك بسهولة تحميل طلباتك بخطوة واحدة باستخدام ملف إكسل',
    },
    {
      id: 6,
      image_url: require('../../assests/images/icons/affiliation.png'),
      title_en: 'Affiliation',
      title_ar: 'تسويق منتجاتك',
      praghrap_en:
        'We will help you in marketing your products and get you daily orders.',
      praghrap_ar: 'سنساعدك في تسويق منتجاتك والحصول على طلباتك اليومية',
    },
  ],
};
