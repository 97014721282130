/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Home from './home';
import About from './about';
import Careers from './careers';
import ContactUs from './contactus';
import AppLayout from '../layout';
import ScrollToTop from '../components/scrollToTop';

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    let html = document.getElementsByTagName('html')[0];
    localStorage.getItem('lang')
      ? i18n.changeLanguage(localStorage.getItem('lang'))
      : i18n.changeLanguage('en');
    if (localStorage.getItem('lang') && localStorage.getItem('lang') === 'ar') {
      html.classList.add('arabic_version');
      html.setAttribute('dir', 'rtl');
    } else {
      html.classList.remove('arabic_version');
      html.setAttribute('dir', 'ltr');
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppLayout>
          <ScrollToTop>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/careers' element={<Careers />} />
              <Route path='/contact-us' element={<ContactUs />} />
            </Routes>
          </ScrollToTop>
        </AppLayout>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
