export const headerData = {
  title_en: 'T-TRANSIT SERVICES',
  title_ar: 'خدامات ت-ترانزيت',
  subtitle_en: 'Your customer is a click away',
  subtitle_ar: 'عميلك على بعد ضغطة واحدة',
  desc_en:
    'Reduce your logistics cost with better service quality with T-Transit. We are your business partners who will support you with the tools you need to grow your business.',
  desc_ar:
    'تقليل تكلفة الشحن الخاصة بك مع جودة خدمة أفضل معنا. نحن شركاء عملك سنقوم بدعمك بالأدوات التي تحتاجها لتنمية عملك',
  list_items: [
    { id: 1, desc_en: 'Order management', desc_ar: 'إدارة الطلبات' },
    { id: 2, desc_en: 'Account management', desc_ar: 'ادارة الحساب' },
    { id: 3, desc_en: 'Reliable service', desc_ar: 'خدمة موثوقة' },
  ],
};

export const servicesData = [
  {
    id: 1,
    title_en: 'AFFILIATION SERVICE',
    title_ar: 'تسويق منتجاتك',
    praghrap_en:
      'Increase your sales with T-Transit by pushing your sales in social media communities and providing periodic reports for the business performance with insights for the trends of the business volume',
    praghrap_ar:
      'زيادة مبيعاتك معنا عن طريق دفع مبيعاتك في مجتمعات وسائل التواصل الاجتماعي وتقديم تقارير دورية عن أداء الأعمال التجارية مع إحصاءات لاتجاهات حجم الأعمال',
    image_url: require('../../../assests/images/affiliation-service.png'),

    list_items: [
      {
        id: 1,
        desc_en: 'Increase your daily sales',
        desc_ar: 'زيادة طلباتك اليومية',
      },
      {
        id: 2,
        desc_en: 'Discover growth opportunities',
        desc_ar: 'اكتشف فرص النمو',
      },
    ],
  },
  {
    id: 2,
    title_en: '360 LOGISTICS SERVICE',
    title_ar: 'خدمة لوجستية متكاملة',
    praghrap_en:
      'Increase your sales with T-Transit by pushing your sales in social media communities and providing periodic reports for the business performance with insights for the trends of the business volume',
    praghrap_ar:
      'استخدام المخازن ، وشركات الشحن من منصة واحدة مع شريك يدير العملية كاملة نيابة عنك',
    image_url: require('../../../assests/images/logistics-service.png'),
    list_items: [
      {
        id: 1,
        desc_en: 'Lowest price in the marke',
        desc_ar: 'أقل سعر للخدمة',
      },
      {
        id: 2,
        desc_en: 'Process governance',
        desc_ar: 'إشراف علي العمليات',
      },
    ],
  },
];
export const featuresData = {
  title_en: 'A PARTNER THAT YOU TRUST',
  title_ar: 'شريك تثق به',
  featuesDetails: [
    {
      id: 1,
      image_url: require('../../../assests/images/icons/insurance.png'),
      title_en: 'Free Insurance',
      title_ar: 'تأمين مجاني',
      praghrap_en:
        'We provide a variety of insurance options in the case of damaged or lost orders',
      praghrap_ar: 'نحن نقدم التأمين في حالة التلف أو الخسارة',
    },
    {
      id: 2,
      image_url: require('../../../assests/images/icons/pickup.png'),
      title_en: 'Free Pickup',
      title_ar: 'تحصيل مجاني',
      praghrap_en:
        'Get your orders picked up for free by your selected courier company.',
      praghrap_ar: 'اختر شركة الشحن لتحصل طلباتك مجانا',
    },
    {
      id: 3,
      image_url: require('../../../assests/images/icons/analysis.png'),
      title_en: 'Analysis',
      title_ar: 'تحليل بيانات',
      praghrap_en:
        'Our dashboard helps you extract data to analyze and compare your profits.',
      praghrap_ar:
        'تساعدك الداشبورد الخاصة بنا على استخراج البيانات لتحليل الإحصائيات ومقارنتها',
    },
    {
      id: 4,
      image_url: require('../../../assests/images/icons/tracking.png'),
      title_en: 'Tracking',
      title_ar: 'تتبع',
      praghrap_en:
        'Just like the magical ball, you can track them through our dashboard anywhere, anytime',
      praghrap_ar:
        'يمكنك تتبع طلباتك من خلال الداشبورد الخاصة بنا في أي وقت وفي أي مكان',
    },
    {
      id: 5,
      image_url: require('../../../assests/images/icons/upload.png'),
      title_en: 'Bulk Upload Orders',
      title_ar: 'سجل طلباتك في خطوة واحدة',
      praghrap_en:
        'You can easily upload all your orders in a bulk using an excel sheet.',
      praghrap_ar: 'يمكنك بسهولة تحميل طلباتك بخطوة واحدة باستخدام ملف إكسل',
    },
    {
      id: 6,
      image_url: require('../../../assests/images/icons/affiliation.png'),
      title_en: 'Affiliation',
      title_ar: 'تسويق منتجاتك',
      praghrap_en:
        'We will help you in marketing your products and get you daily orders.',
      praghrap_ar: 'سنساعدك في تسويق منتجاتك والحصول على طلباتك اليومية',
    },
  ],
};
export const ourCustomersData = {
  title_en: 'HEAR FROM OUR CUSTOMERS',
  title_ar: 'HEAR FROM OUR CUSTOMERS',
  praghrap_en: 'Our customers love chamer, we have nothing to hide.',
  praghrap_ar: 'Our customers love chamer, we have nothing to hide.',
  customers: [
    {
      id: 1,
      image_url: require('../../../assests/images/profile1.png'),
      opinion_en:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      opinion_ar:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      name: 'ALEX PARKINSON',
      email: 'mytravel.com',
    },
    {
      id: 2,
      image_url: require('../../../assests/images/profile1.png'),
      opinion_en:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      opinion_ar:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      name: 'ELIZABETH MARTIN',
      email: 'SaveSpace Inc.',
    },
    {
      id: 3,
      image_url: require('../../../assests/images/profile1.png'),
      opinion_en:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      opinion_ar:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      name: 'ALEX PARKINSON',
      email: 'mytravel.com',
    },
    {
      id: 4,
      image_url: require('../../../assests/images/profile1.png'),
      opinion_en:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      opinion_ar:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      name: 'ALEX PARKINSON',
      email: 'mytravel.com',
    },
    {
      id: 5,
      image_url: require('../../../assests/images/profile1.png'),
      opinion_en:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      opinion_ar:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      name: 'ELIZABETH MARTIN',
      email: 'SaveSpace Inc.',
    },
    {
      id: 6,
      image_url: require('../../../assests/images/profile1.png'),
      opinion_en:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      opinion_ar:
        '"It really saves me time and effort. Chamer is exactly what our business has been lacking. Chamer was worth a fortune to my company."',
      name: 'ALEX PARKINSON',
      email: 'mytravel.com',
    },
  ],
};
