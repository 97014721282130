import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Slide from 'react-reveal/Slide';

import GetStarted from '../getstarted';
import TButton from '../../components/Button';
import List from '../list';

import './index.scss';

const Index = ({ data, showList = true }) => {
  const { i18n } = useTranslation();
  const [visibleModel, setVisibleModel] = useState(false);

  const handleOpenModel = () => {
    setVisibleModel(true);
  };
  return (
    <section className='services'>
      <div className='container'>
        {data?.map((service, index) => {
          return (
            <div
              key={service.id}
              className={classNames('row', 'align-items-center', {
                'flex-row-reverse': index % 2 !== 0,
              })}
            >
              <div className='col-md-6'>
                <Slide right>
                  <div className='services-info'>
                    <h3>
                      {i18n.language === 'en'
                        ? service.title_en
                        : service.title_ar}
                    </h3>
                    <p>
                      {' '}
                      {i18n.language === 'en'
                        ? service.praghrap_en
                        : service.praghrap_ar}
                    </p>
                    {showList && (
                      <>
                        <List listItem={service.list_items} />
                        <TButton
                          width='medium'
                          text_key='receive_call'
                          callback={handleOpenModel}
                        />
                      </>
                    )}
                  </div>
                </Slide>
              </div>
              <div className='col-md-6'>
                <Slide left>
                  <div className='service-media'>
                    <img src={service.image_url} alt='service' />
                  </div>
                </Slide>
              </div>
            </div>
          );
        })}
      </div>
      <GetStarted
        isOpen={visibleModel}
        isCancel={() => setVisibleModel(false)}
      />
    </section>
  );
};

export default Index;
